<template>
  <div class="container">
    <div class="divide"></div>
    <div class="nav">
      <span @click="backHome()">首页 > </span>
      <span @click="backTechnical()">客户项目展示 > </span>
      <span>客户案例详情</span>
    </div>
    <!-- <el-button @click="backHome()">返回列表</el-button> -->
    <div class="content">
      <div class="img">
        <img class="images" :src="info.projectImage" alt="" /> 
        <!-- <img class="images" :src="imgList[index].src" alt="" /> -->
        <!-- <img class="bf" src="../../assets/index/bf.png" alt="" /> -->
        <!-- <div class="imageList">
          <div class="arrowLeft arrow">
            <img src="../../assets/hall/jt1.png" alt="" @click="lastClick()"/>
          </div>
          <div class="middle">
            <div
              class="box"
              v-for="(item, i) in arrayWithIndex.slice(start,end)"
              :key="i"
            >
              <img :class="index==item.index?'imgactive':''" @click="imgClick(item.index)" :src="item.item.src" alt="" />
            </div>
          </div>
          <div class="arrowRight arrow">
            <img src="../../assets/hall/jt2.png" alt="" @click="nextClick()"/>
          </div>
        </div> -->
      </div>
      <!-- 案例标题 -->
      <div class="name">{{ info.projectName }}</div>
      <!-- 案例tabs -->
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="elTabs">
          <el-tab-pane label="案例简介" name="first" class="elTabpPane">
            <p class="intro">
             {{ info.projectDescription }}
            </p>
            <!-- <p class="intro"> 案例介绍:为客户解决因设计缺陷应急回路可靠智能供电。案例亮点:降低客户成本、提供智能化电源切换。</p>
            <p class="intro">案例时间:2022年8月</p> -->
          </el-tab-pane>
          <el-tab-pane label="主要使用产品" name="second">
            <p class="intro">{{ info.clientName }}</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      info: {},
      start: 0,
      end: 4,
      index:0,
      // 图片组
      imgList: [
        {
          src:require('../../assets/cases/t22.png'),
        },
       
        {
          src: "https://img1.baidu.com/it/u=1472391233,99561733&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
        {
          src: "https://img1.baidu.com/it/u=3007048469,3759326707&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
        {
          src: "https://img2.baidu.com/it/u=2028084904,3939052004&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
        {
          src: "https://img1.baidu.com/it/u=4049022245,514596079&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
        {
          src: "https://img1.baidu.com/it/u=3007048469,3759326707&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
        {
          src: "https://img1.baidu.com/it/u=1472391233,99561733&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500",
        },
       
        
      ],
      listLength:0,
      // 案例标签选中
      activeName: "first",
    };
  },
  
  computed: {
    arrayWithIndex() {
      return this.imgList.map((item, index) => {
        return { index, item };
      });
    },
  },
  methods: {
    // 返回首页
    backHome() {
      this.$router.push("/index");
      this.$store.commit("changeTheme", true);
      this.$store.commit("changeIndex", 0);
    },
    // 返回客户项目展示
    backTechnical(){
        this.$router.push("/case");
      this.$store.commit("changeTheme", false);
      this.$store.commit("changeIndex", 3);
    },
    // 标签页切换
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 上一个
    lastClick(){
        if (this.start > 0) {
        this.index--;
        this.start--;
        this.end--;
      } else {
        if (this.index > 0) {
          this.index--;
        } else {
          return;
        }
      }
    },
    // 下一个
    nextClick(){
        if (this.listLength == 2 ) {
        if (this.index > 0) {
          return;
        } else {
          this.index++;
        }
      } else if(this.listLength==3){
        if (this.index > 1) {
          return;
        } else {
          this.index++;
        }
      }else {
        if (this.index < 3) {
          this.index++;
        } else {
          // console.log(this.videoIndex)

          if (this.end < this.listLength) {
            this.index++;
            this.start++;
            this.end++;
          }
        }
      }
      console.log("this.videoIndex:" + this.index);
      console.log("end:" + this.end);
      console.log("start:" + this.start);
    },
    // 点击轮播图片
    imgClick(i){
        this.index = i;
    },
  },
  created() {
  
    this.info =JSON.parse(localStorage.getItem('customDetail'));
    // console.log(this.info)
    this.listLength = this.imgList.length;
  },
};
</script>
<style lang="scss">
/* tab标签 */
.tabs {
  width: 19rem;
  margin: 0 auto;
  text-align: left;
  .el-tabs__active-bar {
    width: 9.5rem !important;
  }
  .el-tabs__nav {
    background: #f5f5f5;
    text-align: center;
    height: 1rem;
    line-height: 1rem;
  }
  #tab-first,
  #tab-second {
    width: 9.5rem !important;
    font-size: 0.3rem !important;
  }
  #pane-first,
  #pane-second {
    padding: 0.3rem 2rem;
    box-sizing: border-box;
  }
  .intro {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #272727;
    line-height: 0.5rem;
  }
}
</style>

<style scoped lang="scss">
.container .divide {
  height: 1.1rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}
.el-button {
  float: right;
  margin-top: 0.3rem;
  margin-right: 0.4rem;
}
/* 导航条 */
.nav {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-top: 2px solid rgba(219, 219, 219, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 42px;
  font-size: 0.2rem;
  z-index: 99;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(3) {
    color: #565656;
    cursor: pointer;
  }
}
.content {
  padding: 0.3rem 0;
  text-align: center;
  //   公司展示视频
  .img {
    width: 8rem;
    height: auto;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    .images {
      width: 100%;
      height: auto;
      object-fit: cover; /* 使用cover值来调整内容呈现方式 */
    }
    .bf {
      width: 1.5rem;
      height: auto;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    //   底部轮播
    .imageList {
      width: 8rem;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 0;
      left: calc(50% - 4rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 中间部分
      .middle {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 7rem;
        // background: red;
        .box {
          img {
            width: 1.7rem;
            margin: 0.2rem 0.05rem 0.2rem 0;
          }
          .imgactive{
            border: 2px solid #fff;
          }
        }
      }
      // 左右两个箭头
      .arrow {
        img {
          width: 0.3rem;
          margin: 0 0.1rem;
        }
      }
    }
  }
  // 客户案例标题
  .name {
    font-weight: bold;
    font-size: 0.35rem;
    text-align: center;
    cursor: pointer;
    margin: 0.5rem 0;
  }
  // 案例tabs
}
</style>

